/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Exercise } from 'src/models/exercise';
import { ExerciseStat } from 'src/models/exercise_stat';
import { Lesson } from 'src/models/lesson';
import { LessonStat } from 'src/models/lesson_stat';
import { Profile } from 'src/models/profile';
import { Quiz } from 'src/models/quiz';
import { QuizStat } from 'src/models/quiz_stat';
import { Remarketing } from 'src/models/remarketing';
import { Unit } from 'src/models/unit';
import { DbService } from './db.service';
import { HttpService } from './http.service';
// eslint-disable-next-line @typescript-eslint/naming-convention
//import * as PouchDB from 'node_modules/pouchdb';
import PouchDB from 'pouchdb';



@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  db; // The stats Pouch database reference
  httpDataProvider: HttpService;

  constructor(httpDataProvider: HttpService) {
    this.initializeDB();
    this.httpDataProvider = httpDataProvider;
  }

  getDB() {
    if (this.db === undefined) {
      this.db = new PouchDB(environment.PROFILE_DB_NAME);
    }
    return this.db;
  }

  initializeDB(): void {
    this.getDB();
    let profile: Profile;
    this.getProfile().then(result => {
      profile = result;
      //this.replicateDB(profile);
      if (profile == null) {
        this.createProfile().catch(e => console.log(e));
      }
    });
  }

  createProfile(): Promise<Profile> {
    const profile = new Profile();
    profile._id = 'profile';
    return this.saveProfile(profile).then(result => result);
  }

  /**
   * Currently, Student's "Profile" object maps to the "Student" object since the TGYN
   * user is the Student and their profile represents the student.  All apps that are
   * going to be associated with Portal (TGYN1/2/3, English Pronunciation Tutor, so on), will
   * be syncing to this single student profile.  However, care must be taken when
   * updating (Patching) so that one app doesn't overwrite another app's data.
   *
   * @param profile
   */
  async syncProfile(profile: Profile, unitId: string = null, lessonNumberToUpdate: number = null): Promise<boolean> {

    // If user hasn't logged in, then we can't do any sync to Portal.  Return:
    console.log("syncProfile - local profile: ", JSON.stringify(profile));
    if (!profile.email) {
      console.log('User don\'t logged');
      return;
    }

    /*
    TODO: Make sure that there is email, password and other required fields before sync.
    If necessary, ensure that firstName and lastName are set to _not_ required in the portal
    in case the client prefers a single name field rather than a breakdown between
    first name and last name.
    For now, test data inserted into profile for all required fields.
    */
    // profile.email = "test@testing.com";
    // profile.password = "test";
    // profile.firstName = "first test";
    // profile.lastName = "last name";
    // profile.birthDate = "19900101";

    if (!profile.portalId) {
      return this.createRemoteProfile(profile);
    } else {
      return new Promise<boolean>(resolve => {
        // We need to send also the product to just return the ones that are related in TGYN1 or TGYN2
        this.httpDataProvider.getProfileFromPortal(profile, environment.PROFILE_SYNC_URL).subscribe(
          (remoteProfile: any) => {
            console.log('Remote profile:', JSON.stringify(remoteProfile));
            //console.log(JSON.stringify(remoteProfile));
            //console.log("LOCAL PROFILE", JSON.stringify(profile));

            /*
            Portal's MySQL record ID should come in the "id" field, if not, then
            we can assume the record isn't found since the findone method sends
            back an empty response without the student profile populated.
           */
            if (!remoteProfile || !remoteProfile.id) {
              return this.createRemoteProfile(profile);
            } else {
              /*
            Else, if we could find an existing profile with a more recent update date,
            then update local profile with the
            remote profile while ensuring that we don't remove premium mode
            locally.
           */
              console.log('Comparing Profiles');
              console.log('Local Profile', profile.updatedAt);
              console.log('Remote Profile', remoteProfile.updatedAt);
              if (profile.updatedAt && remoteProfile.updatedAt === profile.updatedAt) {
                console.log('Doing nothing witht he profile since its up to date locally');
                return;
              }
              if (profile.updatedAt && remoteProfile.updatedAt > profile.updatedAt) {
                // Let's get the data since the server has more recent data.
                console.log('Update the profile from server:');
                profile.premiumMode = remoteProfile.premiumMode;
                profile.lessonStats = remoteProfile.lessonStats;
                profile.firstName = remoteProfile.firstName;
                profile.lastName = remoteProfile.lastName;
                profile.updatedAt = remoteProfile.updatedAt;
                profile.courseProduct = remoteProfile.courseProduct.filter(cp => cp.product.name === environment.APP_NAME);
                this.saveProfile(profile);
                resolve(true);
              } else {

                console.log('Update profile on the Portal');

                /*
                  * Let's put the data from the local machine onto the Portal server.
                  * Its very important that we update the data in a *sane* manner:
                  * - there should be an update method that should be guarding the patch
                  *   to ensure that we aren't overwriting any data from other TGYN apps.
                  * - the guard should also ensure that we don't mistakenly switch
                  *   from Premium mode to non-premium mode for an application.
                */

                profile.id = profile.portalId;
                profile.lessonStats.forEach(l => {
                  l.product = { name: environment.APP_NAME };
                });

                if (lessonNumberToUpdate) {
                  console.log('Sync Profile - Updating Just a lesson', JSON.stringify(profile));

                  // Getting the lesson to be updated.
                  console.log(`Searching for UnitId: ${unitId} and Lesson: ${lessonNumberToUpdate}`);
                  const lessonToBeUpdatedIndex = profile.lessonStats
                    .findIndex(currentLesson => currentLesson.unitId === unitId && currentLesson.lessonNumber === lessonNumberToUpdate);

                  if (lessonToBeUpdatedIndex >= 0) {
                    this.httpDataProvider.updateLessonOnPortal(profile.lessonStats[lessonToBeUpdatedIndex], profile.id).subscribe(
                      async (lessonUpdated: LessonStat) => {
                        console.log('lessonUpdated', JSON.stringify(lessonUpdated));
                        profile.lessonStats[lessonToBeUpdatedIndex] = lessonUpdated;
                        await this.saveProfile(profile);
                        console.log('New Profile with lesson updated', JSON.stringify(profile));
                        resolve(true);
                      },
                      error => {
                        console.log('updateLessonOnPortal:', error);
                        resolve(false);
                      }
                    );
                  } else {
                    console.log(`Lesson to be updated number: [${lessonNumberToUpdate}] not found in the current profile`);
                  }
                } else {
                  console.log('Updating the whole Profile into the Portal API');
                  this.httpDataProvider.updateProfileOnPortal(profile, DbService.profileSyncUrl).subscribe(
                    (updateProfile: Profile) => {
                      console.log('Profile Updated on the Portal');
                      console.log(updateProfile);
                      console.log('Saving Profile Locally');
                      this.saveProfile(profile);
                      resolve(true);
                    },
                    error => {
                      console.log('updateProfileOnPortal:', error);
                      resolve(false);
                    }
                  );
                }

              }
            }
          },
          error => {
            console.log('getProfileFromPortal:', error);
            resolve(false);
          }
        );
      });
    }
  }

  getProfile(): Promise<Profile> {
    return new Promise((resolve) => {
      this.getDB().allDocs({ include_docs: true }).then(result => {
        console.log('getProfile', JSON.stringify(result));
        if (result != null && result.rows.length > 0) {
          return resolve(result.rows[0].doc);
        }
        return resolve(null);
      });
    });
  }

  removeProfile(): void {
    this.getProfile().then(profile => {
      this.getDB().remove(profile._id, profile._rev).then(result => {
        if (result.ok) {
          console.log('Removing profile', result);
          this.createProfile().catch(e => console.log('Error creating profile', e));
        } else {
          console.log('Error removing profile');
        }
      });
    });
  }

  saveProfile(profile: Profile): Promise<Profile> {

    return new Promise((resolve, reject) => {
      // console.log("saveProfile - profile", JSON.stringify(profile));
      console.log('saveProfile', profile.lessonStats);
      if (profile !== undefined) {
        return this.getDB()
          .put(profile)
          .then(data => {
            // console.log("saveProfile - updated profile : ", JSON.stringify(profile));
            data._rev = data.rev;
            return resolve(data);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      }
      return reject(null);
    });
  }

  /**
   *
   * @param unitId The Unit's ID for which we are storing the Quiz Stats
   * @param lesson that contains this quiz
   * @param exercise that contains this quiz
   * @param quizStat the actual QuizStat to store
   * @param lesson is the current lesson for which we are updating stats
   */
  public async saveQuizStats(unitId: string, lesson: Lesson, exercise: Exercise, quizStat: QuizStat) {

    console.log('saveQuizStats - saving quiz stats');

    const profile = await this.getProfile();

    console.log('saveQuizStats - After get profile');

    let found = false;
    let currentLessonStat: LessonStat;
    let currentExerciseStat: ExerciseStat;

    currentLessonStat = this.findLessonStat(profile, unitId, lesson.number);
    if (!currentLessonStat) {
      currentLessonStat = this.addLessonStat(profile, unitId, lesson.number);
    }

    console.log('saveQuizStats - After findLessonStat');

    currentExerciseStat = this.findExerciseStat(currentLessonStat, exercise.number);
    if (!currentExerciseStat) {
      currentExerciseStat = this.addExerciseStat(currentLessonStat, exercise.number);
    }

    console.log('saveQuizStats - After findExerciseStat');

    if (!currentExerciseStat.quizStats) {
      currentExerciseStat.quizStats = [];
    } else {
      currentExerciseStat.quizStats.forEach((stat, index) => {
        if (stat.quizId === quizStat.quizId) {
          currentExerciseStat.quizStats[index] = quizStat;
          found = true;
        }
      });
    }
    if (!found) {
      currentExerciseStat.quizStats.push(quizStat);
    }

    console.log('saveQuizStats - 11111111111111111');

    this.updateExerciseStats(currentExerciseStat, exercise);
    this.updateLessonStats(currentLessonStat, lesson);

    console.log('saveQuizStats - 3333333333333');
    const newDate = new Date();
    newDate.setMilliseconds(newDate.getMilliseconds() + 1800);
    profile.updatedAt = newDate.getTime();

    const updatedProfile = await this.saveProfile(profile);

    console.log('SAVEQUIZSTATS---------------------------------', quizStat.incomplete);

    if (!quizStat.incomplete) {
      profile._rev = updatedProfile._rev;
      // Updating the Exercise on the API

      await this.syncProfile(profile, unitId, lesson.number);
    }
  }

  public getQuizStatsFromProfile(profile: Profile, quiz: Quiz): QuizStat {
    let foundQuizStat: QuizStat;

    profile.lessonStats.forEach(lessonStat => {
      lessonStat.exerciseStats.forEach(exerciseStat => {
        exerciseStat.quizStats.forEach(stat => {
          if (stat.quizId === quiz._id) {
            foundQuizStat = stat;
          }
        });
      });
    });
    return foundQuizStat;
  }

  public getQuizStats(quiz: Quiz): Promise<QuizStat> {
    return this.getProfile().then(profile => this.getQuizStatsFromProfile(profile, quiz));
  }

  getQuizzesCompletedFromProfile(profile: Profile, exercises: Exercise[]): boolean[] {
    const exercisesCompleted: boolean[] = [];
    exercises.forEach(() => exercisesCompleted.push(true));
    exercises.forEach((exercise, index) => {
      if (!exercise.quizes || exercise.quizes.length === 0) {
        exercisesCompleted[index] = false;
      } else {
        exercisesCompleted[index] = true;
        for (let i = 0; i < exercise.quizes.length; i++) {
          const stat = this.getQuizStatsFromProfile(profile, exercise.quizes[i]);
          if (!stat || stat.incomplete) {
            exercisesCompleted[index] = false;
          }
        }
      }
    });
    return exercisesCompleted;
  }

  /**
   * Determine which of the exercises have been completed.
   */
  quizesCompleted(exercises: Exercise[]): Promise<boolean[]> {
    return this.getProfile().then(p => this.getQuizzesCompletedFromProfile(p, exercises));
  }

  getExercisesCompletedFromProfile(
    profile: Profile,
    unitId: string,
    lessons: Lesson[]
  ) {
    const lessonsProgress: number[] = [];
    lessons.forEach(() => lessonsProgress.push(0)); // assume no progress
    lessons.forEach((lesson, index) => {
      // Result is considered an extra
      const result = this.getQuizzesCompletedFromProfile(
        profile,
        lesson.exercises
      );
      if (this.isReflectionCompletedForLesson(profile, unitId, lesson)) {
        result.push(true);
      } else {
        result.push(false);
      }
      lessonsProgress[index] =
        (result.filter(r => r === true).length / result.length) * 100;
    });
    return lessonsProgress;
  }

  /**
   * Determine which of the exercises have been completed.  This will include the count for the
   * reflection exercise.
   */
  exercisesCompletedPercent(unit: Unit): Promise<number[]> {
    return this.getProfile().then(p => this.getExercisesCompletedFromProfile(p, unit._id, unit.lessons));
  }

  /**
   * Find out how many exercises are completed in this lesson
   *
   * @param lesson
   * @returns
   */
  numberOfExercisesCompletedInLesson(lesson: Lesson): Promise<number> {
    return this.quizesCompleted(lesson.exercises).then(res => {
      const exerciseCompleted: boolean[] = [];

      lesson.exercises.forEach(() => exerciseCompleted.push(false));
      res.forEach((value, index) => {
        exerciseCompleted[index] = value;
      });

      return exerciseCompleted.filter(e => e === true).length;
    });
  }

  /**
   * Utility method to help find out whether all exercises in this lesson are completed.
   *
   * @param lesson
   * @returns
   */
  areAllExercisesCompletedInLesson(lesson: Lesson): Promise<boolean> {
    return this.numberOfExercisesCompletedInLesson(lesson).then(
      numberCompleted => numberCompleted >= lesson.exercises.length
    );
  }

  isReflectionCompletedForLesson(
    profile: Profile,
    unitId: string,
    lesson: Lesson
  ): boolean {
    const lessonStat = this.getLessonStatsFromProfile(
      profile,
      unitId,
      lesson.number
    );
    if (
      !lessonStat ||
      !lessonStat.confidenceLevel ||
      lessonStat.confidenceLevel === 0
    ) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param unitId unit Id for which we want to save the confidence
   * @param lessonNumber the lesson number in the unit
   * @param confidenceLevel the confidence chosen by the user
   */
  async saveLessonConfidence(unitId: string, lessonNumber: number, confidenceLevel: number): Promise<void> {

    const profile = await this.getProfile();
    const stat = this.getLessonStatsFromProfile(profile, unitId, lessonNumber);
    if (stat) {
      stat.confidenceLevel = confidenceLevel;
      await this.saveProfile(profile);
      const newDate = new Date();
      newDate.setMilliseconds(newDate.getMilliseconds() + 1800);
      profile.updatedAt = newDate.getTime();
      await this.syncProfile(profile);
    }

  }

  getLessonConfidence(unitId: string, lessonNumber: number): Promise<number> {
    return this.getProfile().then(profile => {
      const lessonStats: LessonStat[] = profile.lessonStats.filter(
        stat => stat.unitId === unitId && stat.lessonNumber === lessonNumber
      );

      if (!lessonStats || lessonStats.length === 0) {
        return null;
      }
      return lessonStats[0].confidenceLevel;
    });
  }

  getLessonStats(unitId: string, lessonNumber: number): Promise<LessonStat> {
    return this.getProfile().then(profile => this.getLessonStatsFromProfile(profile, unitId, lessonNumber));
  }

  getLessonStatsFromProfile(profile: Profile, unitId: string, lessonNumber: number): LessonStat {
    const stats = profile.lessonStats.filter(
      stat => stat.unitId === unitId && stat.lessonNumber === lessonNumber
    );
    if (!stats || stats.length === 0) {
      return null;
    }
    return stats[0];
  }

  /**
   * Returns the next random remarketing ad that we can show the user.
   *
   * @param profile
   * @param remarketingAds
   * @returns
   */
  getNextRemarketingAd(
    profile: Profile,
    remarketingAds: Remarketing[]
  ): Remarketing {
    const ads = remarketingAds
      .slice()
      .filter(r => profile.remarketingAdsShown.indexOf(r.number) < 0);
    return ads[Math.floor(Math.random() * ads.length)];
  }

  /**
   * Ensures that profile is set to premium mode and stores the profile.
   *
   * @param profile
   */
  setPremiumMode(profile: Profile): void {
    profile.premiumMode = true;
    profile.timeOfPurchase = new Date().toJSON();
    this.saveProfile(profile);
  }

  signupStudent(profile: Profile): void {
    console.log(profile);
    this.saveProfile(profile);
  }

  /**
   * Returns a promise with a success or failure boolean flag
   *
   * @param profile
   */
  public createRemoteProfile(profile: Profile): Promise<any> {
    /*
          If we could not find a profile, create it!
          Note that we should let the portal know which App's profile
          this is... so, we need to add the product name to the profile.
          This will map to the Product model in the backend.
         */
    profile.lessonStats.forEach(l => {
      l.product = { name: environment.APP_NAME };
    });

    return new Promise<any>(resolve => {
      this.httpDataProvider.createProfileOnPortal(profile, DbService.profileSyncUrl).subscribe(
        (remoteProfile: Profile) => {
          console.log('Created profile.');
          //console.log(remoteProfile);
          // Let's store the remote profile's ID since that will be used for GET findOne request
          profile.portalId = remoteProfile.id;
          profile.updatedAt = remoteProfile.updatedAt;
          //profile.password = remoteProfile.password;
          this.saveProfile(profile);
          resolve(true);
        },
        (error) => {
          console.log('Faced an error!');
          console.log('createProfileOnPortal:', JSON.stringify(error));
          resolve(error);
        }
      );
    });
  }

  /*
  // NO profile replication via Pouch.  We'll talk with the
  // Portal API.
  replicateDB(profile: Profile): void {
    const remoteDBname = this.generateUserDatabaseName(profile);

    if ( remoteDBname != null ) {
      this.getDB().replicate.from(
        ENV['REMOTE_PROFILE_SYNC_URL'] + remoteDBname, {
        username: ENV['REMOTE_PROFILE_USER'],
        password: ENV['REMOTE_PROFILE_PASS']
      })
      .on('error', function(err) {
        console.log("error loading data from remote server");
      })
      .catch( e => console.log(e) );

      this.getDB().replicate.to(
        ENV['REMOTE_PROFILE_SYNC_URL'] + remoteDBname, {
          username: ENV['REMOTE_PROFILE_USER'],
          password: ENV['REMOTE_PROFILE_PASS']
        })
        .on('error', function(err) {
          console.log("error loading data from remote server");
        })
        .catch( e => console.log(e) );
    }
  }
*/



  /**
   * Primarily useful for generating a unique ID.
   *
   * Hold on... let's see what
   * https://www.joshmorony.com/part-2-creating-a-multiple-user-app-with-ionic-2-pouchdb-couchdb/
   * has to say.
   */
  /*private generateUserDatabaseName(profile: Profile): string {
    if (profile == null
      || profile === undefined
      || profile.firstName == undefined
      || profile.birthDate == undefined) {
      return null;
    }
    return profile.firstName + "_" + profile.birthDate;
  }*/

  private findLessonStat(profile: Profile, unitId: string, lessonNumber: number): LessonStat {
    if (!profile.lessonStats) {
      return null;
    }
    const stats = profile.lessonStats.filter(stat => stat.unitId === unitId && stat.lessonNumber === lessonNumber);
    if (stats.length > 0) {
      return stats[0];
    }
    return null;
  }

  private findExerciseStat(currentLessonStat: LessonStat, exerciseNumber: number): ExerciseStat {
    if (!currentLessonStat.exerciseStats) {
      return null;
    }
    const stats = currentLessonStat.exerciseStats.filter(stat => stat.exerciseNumber === exerciseNumber);
    if (stats.length > 0) {
      return stats[0];
    }
    return null;
  }

  private addLessonStat(profile: Profile, unitId: string, lessonNumber: number): LessonStat {
    if (!profile.lessonStats) {
      profile.lessonStats = [];
    }

    const currentLessonStat = new LessonStat();
    currentLessonStat.unitId = unitId;
    currentLessonStat.lessonNumber = lessonNumber;
    currentLessonStat.completionPercent = 0;
    currentLessonStat.confidenceLevel = 0;
    currentLessonStat.overallPercentage = 0;
    currentLessonStat.numberOfRetries = 0;
    profile.lessonStats.push(currentLessonStat);
    return currentLessonStat;
  }

  private addExerciseStat(currentLessonStat: LessonStat, exerciseNumber: number): ExerciseStat {
    if (!currentLessonStat.exerciseStats) {
      currentLessonStat.exerciseStats = [];
    }
    const stat = new ExerciseStat();
    stat.exerciseNumber = exerciseNumber;
    stat.overallPercentage = 0;
    currentLessonStat.exerciseStats.push(stat);
    return stat;
  }

  /**
   * Should be called after the quiz stats have been added,
   * but before the lesson stats are called for an update.
   *
   * @param stat the exercise stat for this quiz
   * @param exercise is the current exercise that we want to update
   */
  private updateExerciseStats(stat: ExerciseStat, exercise: Exercise): void {

    let totalNumberCorrect = 0;
    let totalQuestions = 0;
    let totalQuizzesCompleted = 0;
    let retries = 0;
    let timeTaken = 0;
    let quizPercentageAccumulator = 0;

    // Has to be total Answers here since a single question like "Complete" quiz can
    // contain multiple answers for that single question sentence.
    exercise.quizes.forEach(q => {
      q.questions.forEach(
        question => (totalQuestions += question.answers.length)
      );
    });

    stat.quizStats.forEach((qStat: QuizStat) => {

      if (!stat.dateCompleted || Date.parse(stat.dateCompleted) < Date.parse(qStat.endTime)) {
        stat.dateCompleted = qStat.endTime;
      }
      if (!stat.dateStarted || Date.parse(stat.dateStarted) > Date.parse(qStat.startTime)) {
        stat.dateStarted = qStat.firstAttemptDate;
      }
      if (!qStat.incomplete) {
        totalNumberCorrect += qStat.numberOfCorrectAnswers;
        totalQuizzesCompleted++;
        retries += qStat.numberOfAttempts;
        timeTaken += qStat.totalTimeInvested;
      }

      quizPercentageAccumulator += qStat.finalPercent;
    });

    stat.totalCorrect = totalNumberCorrect;
    stat.totalQuestions = totalQuestions;
    stat.totalQuizzes = exercise.quizes.length;
    stat.totalQuizzesCompleted = totalQuizzesCompleted;
    stat.timeTaken = timeTaken;
    //stat.overallPercentage = Math.round(stat.totalCorrect / stat.totalQuestions * 100);
    // Overall Percentage for the exercise is the average of the Quize percentages.
    stat.overallPercentage = Math.round(
      quizPercentageAccumulator / totalQuizzesCompleted
    );
    stat.numberOfRetries = retries;
  }

  /**
   * Should be called after updating the exercise stats.
   *
   * @param stat the lesson stat element to update
   * @param lesson is the lesson for which we are updating our stats
   */
  private updateLessonStats(stat: LessonStat, lesson: Lesson): void {

    let totalNumberCorrect = 0;
    let retries = 0;
    let timeTaken = 0;

    // Holds the percentage of the quiz currently completed
    const totalQuizCompletion: number[] = [];
    let totalQuestions = 0;
    lesson.exercises.forEach(e =>
      e.quizes.forEach(q => (totalQuestions += q.questions.length))
    );

    stat.numberOfExercises = lesson.exercises.length;
    stat.overallPercentage = 0;

    if (stat.exerciseStats.length > lesson.exercises.length) {
      // It means some how we have repeated rows in the DB, we need to fix it.
      // We will take the last excercise  updated and erase the other ones.
      this.fixRepeatedExercises(stat, lesson);
    }

    stat.exerciseStats.forEach(exerciseStat => {
      if (!stat.dateCompleted || Date.parse(stat.dateCompleted) < Date.parse(exerciseStat.dateCompleted)) {
        stat.dateCompleted = exerciseStat.dateCompleted;
      }
      if (!stat.dateStarted || Date.parse(stat.dateStarted) > Date.parse(exerciseStat.dateStarted)) {
        stat.dateStarted = exerciseStat.dateStarted;
      }
      totalNumberCorrect += exerciseStat.totalCorrect;
      retries += exerciseStat.numberOfRetries;
      timeTaken += exerciseStat.timeTaken;
      totalQuizCompletion.push((exerciseStat.totalQuizzesCompleted / exerciseStat.totalQuizzes) * 100);
      stat.overallPercentage += exerciseStat.overallPercentage;
    });

    while (totalQuizCompletion.length !== lesson.exercises.length) {
      totalQuizCompletion.push(0);
    }

    // Now let us total up the completion of each of the exercises:
    const percentAllocatedToEachExercise = 100 / lesson.exercises.length;
    stat.completionPercent = 0;
    totalQuizCompletion.forEach(completion => {
      stat.completionPercent += (completion * percentAllocatedToEachExercise) / 100;
    });

    stat.completionPercent = Math.round(stat.completionPercent);
    stat.totalCorrect = totalNumberCorrect;
    stat.totalQuestions = totalQuestions;
    stat.timeTaken = timeTaken;
    //stat.totalQuizzes = totalQuizzes;
    //stat.totalQuizzesCompleted = totalQuizzesCompleted;
    //stat.overallPercentage = Math.round(stat.totalCorrect / stat.totalQuestions * 100);
    /*
      Note that the following logic is based on the fact that only completed exercises have
      the dateCompleted field.
     */
    if (stat.exerciseStats.length > 0) {
      stat.overallPercentage = Math.round(stat.overallPercentage / stat.exerciseStats.length);
    }

    stat.numberOfRetries = retries;
    console.log('stat.numberOfRetries', stat.numberOfRetries);
    if (isNaN(stat.numberOfRetries) || stat.numberOfRetries == null) {
      stat.numberOfExercises = 0;
    }
    // if (lesson.exercises.length != stat.exerciseStats.filter(e => e.dateCompleted).length) {
    //   stat.overallPercentage = 0;
    // } else {
    //   stat.overallPercentage = Math.round(stat.overallPercentage / lesson.exercises.length);
    // }
  }


  private fixRepeatedExercises(stat: LessonStat, lesson: Lesson): void {

    // Checking for repeated exercises
    lesson.exercises.forEach(currentExercise => {
      const result = stat.exerciseStats.filter(exerciseStat => exerciseStat.exerciseNumber === currentExercise.number);
      if (result.length > 1) {
        // Means that we have repeated rows.
        result.sort((x, y) => y.createdAt - x.createdAt);
        stat.exerciseStats = stat.exerciseStats.filter(exerciseStat => exerciseStat.exerciseNumber !== currentExercise.number);
        stat.exerciseStats.push(result[0]);
      }
    });
  }




}
