import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BackendResponse } from 'src/models/backend-response';
import { LessonStat } from 'src/models/lesson_stat';
import { Profile } from 'src/models/profile';
import { DbService } from './db.service';
import { SailsResponse } from './sails-response';
import { UtilsService } from './utils.service';

const FACULTY_PASSWORD_RESET_EMAIL = '/api/v1/entrance/send-password-recovery-email';
const RESET_PASSWORD_WITH_TOKEN_URL = '/api/v1/entrance/update-password-and-login';
const UPDATE_PASSWORD = '/student/update-password';
//
const BACKEND_SERVER_NAME = environment.SERVER_NAME;
const STATS_DATA_URL = BACKEND_SERVER_NAME + 'stats/find';
const LESSONS_URL = BACKEND_SERVER_NAME + 'lesson/update';

@Injectable({
  providedIn: 'root'
})
export class HttpService {


  BACKEND_SERVER_NAME: string;

  constructor(
    private utilsService: UtilsService,
    private http: HttpClient, public toastController: ToastController) { }

  getCountryData(): Observable<JSON> {
    return this.http.get('assets/country.json') as Observable<JSON>;
  }

  getUSStateData(): Observable<JSON> {
    return this.http.get('assets/us_state.json') as Observable<JSON>;
  }

  getLanguages(): Observable<JSON> {
    return this.http.get('assets/languages.json') as Observable<JSON>;
  }

  /**
   * Sends a payment capture request to the appropriate backend URL
   * that is set in the database.
   *
   * @param token is the Stripe token
   * @returns SailsResponse
   */
  capturePayment(token: string, paymentUrl: string, paymentAmount: number, paymentCurrency: string): Observable<SailsResponse<boolean>> {
    console.log('Payment URL: ' + paymentUrl);
    console.log('Payment token: ');
    console.log(token);
    return this.http.post(paymentUrl, {
      token,
      amount: paymentAmount,
      currency: paymentCurrency
    }) as Observable<SailsResponse<boolean>>;
  }

  /**
   * This fetches the profile from the Portal
   *
   * @param profile
   * @returns
   */
  getProfileFromPortal(profile: Profile, profileSyncUrl: string): Observable<SailsResponse<Profile>> {
    console.log('Fetching profile from Portal');
    /*
      Find profile by Id. Before fetching the profile though, ensure that we are
      logged in to the portal:
     */

    const params = new HttpParams()
      .set('productId', environment.APP_ID);      

    return this.http.get<SailsResponse<Profile>>(profileSyncUrl + '/findone/' + profile.portalId, { params, withCredentials: true })
      .pipe(catchError(this.utilsService.handleErrorData));
  }

  /**
   * Create profile.  It is the responsibility of the calling method 
   * to confirm that the profile doesn't already exist.  If it does, then
   * you should be updating from/to the remote profile rather than creating one.
   *
   * @param profile
   * @returns
   */
  createProfileOnPortal(profile: Profile, profileSyncUrl: string): Observable<Profile> {
    console.log('Creating profile on Portal');
    return this.http.post<Profile>(profileSyncUrl, { profile }, { withCredentials: true })
      .pipe(catchError(this.utilsService.handleErrorData));
  }

  updateProfileOnPortal(profile: Profile, profileSyncUrl: string): Observable<Profile> {
    console.log('Updating profile on Portal', JSON.stringify(profile));
    return this.http.patch<Profile>(
      profileSyncUrl + '/' + profile.portalId,
      { profile },
      { withCredentials: true }
    )
      .pipe(catchError(this.utilsService.handleErrorData));
  }

  updateLessonOnPortal(lessonToUpdate: LessonStat, profileId: string): Observable<LessonStat> {
    console.log('Updating lesson on portal', JSON.stringify(lessonToUpdate));
    console.log('Updating lesson on portal-----------------2', JSON.stringify(profileId));
    return this.http
      .patch<LessonStat>(LESSONS_URL + '/' + profileId, { lesson: lessonToUpdate, profileId }, { withCredentials: true })
      .pipe(catchError(this.utilsService.handleErrorData));
  }

  sendPasswordResetEmail(email: string): Observable<object> {
    return this.passwordResetEmail(environment.STUDENT_PORTAL_URL + FACULTY_PASSWORD_RESET_EMAIL, email);
  }

  changePassword(userId: string, existingPassword: string, newPassword: string): Observable<object> {
    return this.http
      .patch<any>(
        DbService.studentPortalUrl + UPDATE_PASSWORD,
        {
          userId,
          existingPassword,
          newPassword
        },
        { withCredentials: true }
      )
      .pipe(
        tap(data => {
          if (data.success) {
            return { success: true };
          }
        }),
        catchError(
          this.utilsService.handleError('password reset failure', { success: false })
        )
      );
  }

  resetPassword(token: string, password: string): Observable<object> {
    return this.http
      .post<any>(
        DbService.studentPortalUrl + RESET_PASSWORD_WITH_TOKEN_URL,
        { token, password },
        { withCredentials: true }
      )
      .pipe(
        tap(data => {
          console.log('the data obj');
          console.log(data);
          if (data.token) {
            data.success = true;
          }
        }),
        catchError(
          this.utilsService.handleError('password reset failure', { success: false })
        )
      );
  }

  passwordResetEmail(url: string, email: string): Observable<object> {
    return this.http
      .post<boolean | string>(url, { email }, { withCredentials: true })
      .pipe(
        map(result => ({ success: true, data: result } as any)),
        catchError(
          this.utilsService.handleError('password reset request', {
            success: false
          })
        )
      );
  }

  // CLASS CODES AND PROM CODES
  redeemClassPromCode(data, apiUrl: string): Observable<JSON> {
    console.log('Redeeming Class or Prome Code', data);
    return this.http.post<JSON>(apiUrl + '/code/redeem-code', data, { withCredentials: true })
      .pipe(catchError(this.utilsService.handleErrorData));
  }
  //  stats

  getCourseProductStats(courseId: number, productId: number, studentId: number): Observable<BackendResponse> {
    let parameters = '?courseId=' + courseId + '&productId=' + productId;
    if (studentId) {
      parameters = parameters + '&studentId=' + studentId;
    }

    return this.http.post(STATS_DATA_URL + parameters, { withCredentials: true })
      .pipe(
        map(result => ({ success: true, data: result } as any)),
        catchError(this.utilsService.handleError('Stats failed', ({ success: false } as BackendResponse)))
      );
  }
}
