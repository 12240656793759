import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NO_ERRORS_SCHEMA } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(public toastCtrl: ToastController) {
    console.log('Hello UtilsProvider Provider');
  }

  /**
   * Only for logging for now.
   *
   * @param error
   * @returns
   */
  handleErrorData(error: HttpErrorResponse) {

    console.log('error', error);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else if (error.status === 409) {
      console.log('Status: 409')     
      return throwError(error.error);      
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      let toastMessage =
        'Sorry, seems like we are having technical difficulties.  Please try again at a later date.';

      console.log(`${operation} failed: ${error.message}`);

      if (error.status === 500) {
        result.operationDenied = true;
        if (error.error && error.error.message) {
          result.message = error.error.message;
        }
      } else if (error.status === 403) {
        result.accessDenied = true;
        toastMessage =
          'Access is denied.  Please contact us for further support.';
      } else if (error.status === 498) {
        if (operation === 'password reset failure') {
          result.data = error.error;
        } else {
          result.notUnique = true;
          result.data = error.error;
          toastMessage = 'Seems like this account already exists.  Please login instead.';
        }
      } else if (
        error.status === 409 ||
        (error.error &&
          typeof error.error.toLowerCase === 'function' &&
          error.error.toLowerCase().includes('unique') > -1)
      ) {
        result.notUnique = true;
        toastMessage =
          'Seems like this account already exists.  Please login instead.';
      } else if (error.error && error.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        console.log('An error occurred:', error.message);
        result.networkIssue = true;
        toastMessage = 'Network error in communicating with our server.';
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(`Backend returned code ${error.status}, body was: `);
        if (error.error) {
          result.failureData = error.error;
          if (result.failureData.message === 'Email not found.') {
            result.Email_not_found = true;
          }
          console.log(result.failureData.message);
          if (error.error.code) {
            switch (error.error.code) {
              case 'E_MISSING_OR_INVALID_PARAMS':
                result.validationFailures = error.error.problems;
            }
          }
        }
        result.operationFailed = true;
      }
      const toast = this.toastCtrl.create({
        message: toastMessage,
        duration: 3000,
        position: 'bottom'
      });
      //toast.present();

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'bottom'
    });

    toast.present();
  }
}
