// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APP_NAME: 'The Grammar You Need 1',
APP_ID: 1,
  PROFILE_DB_NAME: 'profile',
  DEFAULT_COLOR: '#fcb14c',
  DB_NAME: 'tgyn1',
  REMOTE_USER: 'tenedingetyrreppecereass',
  REMOTE_PASS: '94920e65d7027baf92788fe0725cc5ad22ad7b38',
  REMOTE_SYNC_URL: 'https://685bad27-f016-42ca-8373-22dc7d802c4d-bluemix.cloudant.com/tgyn1',
  INTERSTITIAL_ID: 'ca-app-pub-3628817704634856/1648721618',
  BANNER_ID: 'ca-app-pub-3628817704634856/1648721618',
  ANDROID_ADMOB_APP_ID: 'ca-app-pub-3628817704634856~7386411252',
  IOS_ADMOB_APP_ID: 'ca-app-pub-3628817704634856~5742157092',
  IOS_INTERSTITIAL: 'ca-app-pub-3628817704634856/3140044485',
  ADMOB_TESTING: true,
  ADMOB_LICENSE: 'mberman@languageartspress.com/a827397b6812ac227ac5efc0093b100c',
  GOOGLE_ANALYTICS_ANDROID: 'UA-117181936-1',
  GOOGLE_ANALYTICS_IOS: 'UA-117181936-1',
  GOOGLE_ANALYTICS_BROWSER: 'UA-117489998-1',
  STRIPE_ID: 'pk_test_B1iCTyfREqPPY1hw48y6kZSW',
  IOS_PRODUCT_ID: '2',
  ANDROID_PRODUCT_ID: '3',  
  BASEURL: 'https://dev-stp.language-arts-press.com/portal', // for student portal
  PAYMENT_URL: 'https://dev-stp-api.language-arts-press.com/stripe/tgyn',
  // PROFILE_SYNC_URL: 'https://dev-stp-api.language-arts-press.com/student',
  // STUDENT_PORTAL_URL: 'https://dev-stp-api.language-arts-press.com',
  // //For dev server database
  // SERVER_NAME: 'https://dev-stp-api.language-arts-press.com/'

  // New API
  PROFILE_SYNC_URL: 'https://stp-api.pro-lingua-learning.com/student',
  STUDENT_PORTAL_URL: 'https://stp-api.pro-lingua-learning.com',
  //For dev server database
  SERVER_NAME: 'https://stp-api.pro-lingua-learning.com/'

  // for local development
  // PROFILE_SYNC_URL: 'http://localhost:1337/student',
  // STUDENT_PORTAL_URL: 'http://localhost:1337',
  // SERVER_NAME: 'http://localhost:1337/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
